


























import {Component, Prop, Vue} from "vue-property-decorator";
import {userNamespace} from '@store/user';
import {
  getUserCertificates,
  Certificate,
  createDetachedSignature,
  createHash
} from 'crypto-pro';
import {IUser} from "@store/user/Interface";

@Component({
  name: 'EcpFormModalForNewFiles'
})
export default class EcpFormModal extends Vue {
  @userNamespace.State('user') public user!: IUser;

  @Prop({type: Boolean, default: false})
  private show!: Boolean

    @Prop({type: File, required:true})
   private file!: File

  private certThumbprint = ''
  protected certs: Certificate[] = []

  private signLoading: boolean = false

  async startSign() {
    try {
      this.signLoading = true
      const arrayBuffer = await this.readFile(this.file)
      const hash = await createHash(arrayBuffer)
      const sign = await createDetachedSignature(this.certThumbprint, hash)
      this.$successNotify()

      this.$emit('filesSigned', sign)
      this.$emit('close')
    } catch (error) {
      console.error(error)
      this.$errorNotify(error?.response?.data?.message)
    } finally {
      this.signLoading = false
    }
  }

  async readFile(messageFile) : Promise<ArrayBuffer> {
    return new Promise(function (resolve) {
      const fileReader = new FileReader();

      fileReader.onload = function () {
        resolve(this.result as ArrayBuffer);
      };

      fileReader.readAsArrayBuffer(messageFile);
    });
  }


  async mounted() {
    try {
      let certs = await getUserCertificates()
      Array.isArray(certs) && (this.certs = certs)
    } catch (e) {
      console.error(e.message)
    }
  }

}
